<template>
  <div>
    <b-card >
      <b-row  class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/Абдукадыр_Шакури.jpg"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Абдуқодир Шакурий <br />(1875-1943)</p></b-card-text
          >
        </b-col>
        <b-col sm="12" md="6">
           <b-card-text>
          <p class="TextBlack17">
            Маърифатпарвар, педагог Абдуқодир Шакурий 1875 йилда Самарқанднинг
            Ражабамин қишлоғида боғбон оиласида туғилди. Шакурий эски усулдаги
            мактабни тамомлаганидан кейин Самарқанд шаҳридаги мадрасага ўқишга
            киради. Шакурий рус гимназиясига бориб, унинг ички тартиб қоидалари
            ва ўқитиш усуллари билан танишади. Шундан кейин ёш муаллимда ўз
            халқининг болалари учун ҳам шу тартибдаги янги мактаб ташкил қилиш
            орзуси пайдо бўлади. Абдуқодир Шакурий Самарқанд гимназиясида кўрган
            ва ўзи орзу қилган янги ўқитиш усуллари Россиянинг турли жойларида
            яшаган бошқа туркий халқларда ҳам мавжудлигини газеталардан билиб
            олади. Шакурий дўстлари ёрдамида Қўқон шаҳрига боради ва у ерда янги
            мактабдаги ўқитиш усуллари билан танишади. Самарқандга қайтиб
            келгач, ўз қишлоғи Ражабаминда 1901 йилнинг кузида биринчи янги
            усулдаги мактабни ташкил қилади. Шакурий мактабининг шуҳрати кенг
            тарқала бошлади, Ўрта Осиёдаги бошқа маърифатпарвар кишиларнинг
            диққатини жалб этди. Шакурий фақат ўқитувчилик қилиш билангина
            чекланиб қолмаган, у ўз мактаби учун дарсликлар ёзиб, уларни ўз
            маблағлари ҳисобига нашр қилдирган. Масалан, унинг «Раҳнамойи савод»
            («Савод чиқариш қўлланмаси») деб аталган китоби шу тариқа чоп
            этилган. Шакурий мактаблар учун «Жомиъ ул-ҳикоят» (1907), «Зубдат
            ул-ашъор» (1907) каби ўқув қўлланмаларини яратган. М. Беҳбудий билан
            биргаликда мактаблар учун дастур тузган. Шакурий дастлаб қизлар учун
            ҳам янги усулдаги мактаб ташкил қилган. Унда ўзи раҳбарлигида
            рафиқаси муаллималик қилган. Кейинчалик ўғил ва қиз болалар гуруҳини
            бирлаштириб ўқита бошлаган. 1921 йилда Самарқанд шаҳридаги
            13-мактабга мудир қилиб тайинланади. У болаларни тарбиялаш, ўқитишга
            катта ғайрат билан киришди, кўп йиллар давомида шу мактабнинг мудири
            ҳамда она тили ва адабиёт ўқитувчиси вазифаларида ишлади. 1925 йилда
            А.Шакурийнинг ташаббуси билан қишлоқ аҳолиси ўз маблағлари ҳисобига
            тўрт синфли янги мактаб қуриб ишга туширади. Абдуқодир Шакурий 1943
            йилда вафот этади.
          </p>
        </b-card-text>
        </b-col>
        <b-col sm="12" md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
       
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
